import React, { createContext, useEffect, useState } from "react";
import UserPool from "./UserPool";
import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";
import qs from "qs";
import axios from "axios";

const AccountContext = createContext();

const Account = (props) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [userAttributes, setUserAttributes] = useState(null);
  const [idToken, setIdToken] = useState();
  const [accessToken, setAccessToken] = useState();

  const [sessionLoaded, setSessionLoaded] = useState(false);

  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = UserPool.getCurrentUser();
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            setCurrentUser(null);
            reject();
          } else {
            user.getUserAttributes(function (err, result) {
              if (err) {
                setUserAttributes(null);
                return;
              }
              let temp = {};
              for (let i of result) {
                temp[i.Name] = i.Value;
              }
              setUserAttributes(temp);

              if (
                session?.idToken?.jwtToken &&
                session?.accessToken?.jwtToken
              ) {
                setIdToken(session?.idToken?.jwtToken);
                setAccessToken(session?.accessToken?.jwtToken);
                setCurrentUser(user);
                resolve(session);
              } else {
                setIdToken(null);
                setAccessToken(null);
                setCurrentUser(null);
                reject();
              }
            });
          }
        });
      } else {
        setIdToken(null);
        setAccessToken(null);
        setCurrentUser(null);
        reject();
      }
    }).catch((err) => {
      setIdToken(null);
      setAccessToken(null);
      setCurrentUser(null);
    });
  };

  useEffect(() => {
    setSessionLoaded(false);

    getSession()
      .then((session) => {
        setSessionLoaded(true);
      })
      .catch((err) => {
        setSessionLoaded(true);
      });
  }, []);

  const refreshTokens = async () => {
    return await new Promise((resolve, reject) => {
      const user = UserPool.getCurrentUser();
      if (user) {
        user.getSession((err, session) => {
          if (err) {
            // Should we logout user here?
            reject();
          } else {
            user.getUserAttributes(function (err, result) {
              if (err) {
                setUserAttributes(null);
                return;
              }
              let temp = {};
              for (let i of result) {
                temp[i.Name] = i.Value;
              }
              setUserAttributes(temp);
              if (
                session?.idToken?.jwtToken &&
                session?.accessToken?.jwtToken
              ) {
                setIdToken(session.idToken.jwtToken);
                setAccessToken(session.accessToken.jwtToken);
                resolve(session);
              } else {
                // Should we logout user here?
                reject();
              }
            });
          }
        });
      } else {
        // Should we logout user here?
        reject();
      }
    }).catch((err) => {
      // Should we logout user here?
    });
  };

  const signUp = async (Email, Firstname, Lastname, Password) => {
    return await new Promise((resolve, reject) => {
      const emailAttribute = new CognitoUserAttribute({
        Name: "email",
        Value: Email,
      });
      const firstName = new CognitoUserAttribute({
        Name: "custom:given_name",
        Value: Firstname,
      });
      const lastName = new CognitoUserAttribute({
        Name: "custom:family_name",
        Value: Lastname,
      });

      var Username =
        Email.split("@")[0].toLowerCase() +
        "-" +
        Math.floor(Math.random() * 1000000);

      UserPool.signUp(
        Username,
        Password,
        [emailAttribute, firstName, lastName],
        null,
        (err, data) => {
          if (err) {
            reject(err);
            return;
          }
          resolve(data);
        },
      );
    });
  };

  const authenticate = async (Email, Password) => {
    return await new Promise((resolve, reject) => {
      axios
        .post(
          process.env.REACT_APP_GRIGORA_API_URL + "/general/user/get_username",
          qs.stringify({ email: Email }),
        )
        .then((res) => {
          var Username = res?.data?.Output?.username;

          if (!Username) {
            reject();
            return;
          }

          const user = new CognitoUser({
            Username: Username,
            Pool: UserPool,
          });

          const authDetails = new AuthenticationDetails({
            Username: Username,
            Password: Password,
          });

          user.authenticateUser(authDetails, {
            onSuccess: (data) => {
              getSession();
              resolve(data);
            },
            onFailure: (err) => {
              getSession();
              reject(err);
            },
          });
          return;
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const verifyUserAccount = async (Email, code) => {
    return await new Promise((resolve, reject) => {
      axios
        .post(
          process.env.REACT_APP_GRIGORA_API_URL + "/general/user/get_username",
          qs.stringify({ email: Email }),
        )
        .then((res) => {
          var Username = res?.data?.Output?.username;

          if (!Username) {
            reject();
            return;
          }

          const user = new CognitoUser({
            Username: Username,
            Pool: UserPool,
          });

          user.confirmRegistration(code, true, function (err, result) {
            if (err) {
              reject(err);
              return;
            }
            resolve(result);
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const sendUserVerificationCode = async (Email) => {
    return await new Promise((resolve, reject) => {
      axios
        .post(
          process.env.REACT_APP_GRIGORA_API_URL + "/general/user/get_username",
          qs.stringify({ email: Email }),
        )
        .then((res) => {
          var Username = res?.data?.Output?.username;

          if (!Username) {
            reject();
            return;
          }

          const user = new CognitoUser({
            Username: Username,
            Pool: UserPool,
          });

          user.resendConfirmationCode(function (err, result) {
            if (err) {
              reject(err);
              return;
            }
            resolve(result);
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const changeUserPassword = async (Email, code, password) => {
    return await new Promise((resolve, reject) => {
      axios
        .post(
          process.env.REACT_APP_GRIGORA_API_URL + "/general/user/get_username",
          qs.stringify({ email: Email }),
        )
        .then((res) => {
          var Username = res?.data?.Output?.username;

          if (!Username) {
            reject();
            return;
          }

          const user = new CognitoUser({
            Username: Username,
            Pool: UserPool,
          });

          user.confirmPassword(code, password, {
            onSuccess() {
              resolve();
            },
            onFailure(err) {
              reject(err);
            },
          });
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const sendForgotPasswordCode = async (Email) => {
    return await new Promise((resolve, reject) => {
      axios
        .post(
          process.env.REACT_APP_GRIGORA_API_URL +
            "/general/user/send_forget_password_code",
          qs.stringify({ email: Email }),
        )
        .then((res) => {
          resolve(res);

          // var Username = res?.data?.Output?.username;

          // if (!Username) {
          //   reject();
          //   return;
          // }

          // const user = new CognitoUser({
          //   Username: Username,
          //   Pool: UserPool,
          // });

          // //

          // user.forgotPassword({
          //   onSuccess: function (data) {
          //     resolve(data);
          //   },
          //   onFailure: function (err) {
          //     reject(err);
          //   },
          // });
        })
        .catch((err) => {
          reject(err);
        });
    });
  };

  const logOut = async () => {
    return await new Promise((resolve, reject) => {
      const user = UserPool.getCurrentUser();
      if (user) {
        user.signOut((data) => {
          getSession();
          resolve();
        });
      } else {
        getSession();
        resolve();
      }
    });
  };

  const signInFederated = (identityProvider) => {
    let authHost = "https://grigora.auth.ap-south-1.amazoncognito.com";

    let redirectUri;
    if (
      !process.env.REACT_APP_NODE_ENV ||
      process.env.REACT_APP_NODE_ENV === "development"
    ) {
      redirectUri = `${
        window.location.protocol + "//" + window.location.host
      }/oauth`;
    } else {
      redirectUri = process.env.REACT_APP_GRIGORA_DEPLOY_URL + "/oauth/";
    }
    let responseType = "code";
    let clientId = process.env.REACT_APP_AWS_COGNITO_CLIENT;
    let state = "some_state";
    let scope = "profile email openid aws.cognito.signin.user.admin";
    let access_type = "offline_access";
    let code_challenge = "adsasasdibausydbuasbduhyb";
    let authUrl = `${authHost}/oauth2/authorize?identity_provider=${identityProvider}&code=${code_challenge}&redirect_uri=${redirectUri}&response_type=${responseType}&client_id=${clientId}&state=${state}&scope=${scope}&access_type=${access_type}`;

    var childWindow = window.open(
      authUrl,
      "awsGoogleAuth",
      "location,toolbar,resizable,scrollbars,status,width=600,height=600",
    );

    var maxTries = 0;

    window.addEventListener(
      "message",
      (res) => {
        if (res.data.action === "request_again") {
          // Only request maximum of 5 times.
          if (maxTries > 4) {
            childWindow.close();
            return;
          }
          childWindow.location.replace(authUrl);
          maxTries++;

          return;
        }
        if (res.data.action === "login") {
          let tokensData = res.data.data;

          axios
            .post(
              process.env.REACT_APP_GRIGORA_API_URL +
                "/general/user/oauth_username",
              qs.stringify({ access_token: tokensData.AccessToken }),
            )
            .then((res) => {
              var user = new CognitoUser({
                Username: res?.data?.Output?.data?.Username,
                Pool: UserPool,
              });

              user.signInUserSession = user.getCognitoUserSession(tokensData);
              user.cacheTokens();
              getSession();
              return;
            })
            .catch((err) => {
              console.log(err);
            });
        }
      },
      false,
    );
  };

  const signInFederatedGoogle = () => {
    let authHost = "https://accounts.google.com";

    let redirectUri;
    if (
      !process.env.REACT_APP_NODE_ENV ||
      process.env.REACT_APP_NODE_ENV === "development"
    ) {
      // let authHost = "https://grigora.auth.ap-south-1.amazoncognito.com";
      redirectUri = `https://grigora.auth.ap-south-1.amazoncognito.com/oauth2/idpresponse`;
    } else {
      redirectUri = process.env.REACT_APP_GRIGORA_DEPLOY_URL + "/oauth/";
    }
    let responseType = "code";
    let clientId =
      "796804839287-vvnm4no80o12bdm12af1n2sbg4drfimu.apps.googleusercontent.com";
    let scope = "profile email openid";
    let authUrl = `${authHost}/o/oauth2/v2/auth?redirect_uri=${redirectUri}&response_type=${responseType}&client_id=${clientId}&scope=${scope}&prompt=consent&access_type=offline`;

    console.log(authUrl);

    var childWindow = window.open(
      authUrl,
      "awsGoogleAuth",
      "location,toolbar,resizable,scrollbars,status,width=600,height=600",
    );

    var maxTries = 0;

    window.addEventListener(
      "message",
      (res) => {
        if (res.data.action === "request_again") {
          // Only request maximum of 5 times.
          if (maxTries > 4) {
            childWindow.close();
            return;
          }
          childWindow.location.replace(authUrl);
          maxTries++;

          return;
        }
        if (res.data.action === "login") {
          let tokensData = res.data.data;

          axios
            .post(
              process.env.REACT_APP_GRIGORA_API_URL +
                "/general/user/oauth_username",
              qs.stringify({ access_token: tokensData.AccessToken }),
            )
            .then((res) => {
              var user = new CognitoUser({
                Username: res?.data?.Output?.data?.Username,
                Pool: UserPool,
              });

              user.signInUserSession = user.getCognitoUserSession(tokensData);
              user.cacheTokens();
              getSession();
              return;
            })
            .catch((err) => {
              console.log(err);
            });
        }
      },
      false,
    );
  };

  useEffect(() => {
    // Refresh Tokens every 120 seconds
    const timer = setInterval(() => {
      refreshTokens();
    }, 2 * 30 * 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <AccountContext.Provider
      value={{
        authenticate,
        signUp,
        getSession,
        logOut,
        currentUser,
        userAttributes,
        idToken,
        accessToken,
        signInFederated,
        signInFederatedGoogle,
        sessionLoaded,
        setSessionLoaded,
        verifyUserAccount,
        sendUserVerificationCode,
        changeUserPassword,
        sendForgotPasswordCode,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};
export { Account, AccountContext };
